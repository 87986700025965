import { render, staticRenderFns } from "./invite-friends.vue?vue&type=template&id=842c1a16&scoped=true"
import script from "./invite-friends.vue?vue&type=script&lang=js"
export * from "./invite-friends.vue?vue&type=script&lang=js"
import style0 from "./invite-friends.vue?vue&type=style&index=0&id=842c1a16&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "842c1a16",
  null
  
)

export default component.exports
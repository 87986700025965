<template>
  <f7-page no-toolbar no-swipeback name="bank-info" class="page-bank-info">
    <f7-navbar :no-hariline="true" innerClass="inner-bankInfo">
      <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t(isBinding ? "bank_info_0001" : "change_security_pwd_0016") }}</f7-nav-title>
    </f7-navbar>
    <template v-if="showInfo == 1">
      <changeSecurityPwd @custom-event="handleCustomEvent"></changeSecurityPwd>
    </template>
    <template v-else-if="showInfo == 2">
      <template>
        <f7-block-title class="block-bank-title text-center">*{{ $t("bank_info_0007") }}*</f7-block-title>
        <f7-block class="block-bank-info">
          <!-- <div class="row no-gap row-choose">
            <div class="col-50" :class="{ active: bankInfoForm.IsVirtualCurrencyBank === false }" @click="choosePoint(false)">
              <p class="itme-point">{{ $t("bank_info_0029") }}</p>
            </div>
            <div class="col-50" :class="{ active: bankInfoForm.IsVirtualCurrencyBank === true }" @click="choosePoint(true)">
              <p class="itme-point">{{ $t("bank_info_0030") }}</p>
            </div>
          </div> -->
          <div v-if="bankInfoForm.IsVirtualCurrencyBank" class="form">
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("bank_info_0025") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('bank_info_0026')" v-model="bankInfoForm.BankAccountNo_Virtul" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <a class="smart-select smart-select-init" data-open-in="sheet">
                <select v-model="bankInfoForm.BankName_Virtul">
                  <option v-for="item in bankList_Virtul" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("bank_info_0027") }}</div>
                    <!-- <div class="item-after item-input-wrap">{{ bankList[0] ? bankList[0][1] : '' }}</div> -->
                    <div class="item-after item-input-wrap max"></div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div v-else class="form">
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("bank_info_0008") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('bank_info_0009')" v-model="bankInfoForm.SureName" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("bank_info_0010") }}</div>
                <div class="item-input-wrap">
                  <input type="number" oninput="validity.valid||(value='');" min="0" step="1" :placeholder="$t('bank_info_0011')" v-model="bankInfoForm.BankAccountNo" />
                </div>
              </div>
            </div>
            <div class="form-item" v-show="bankInfoForm.BankName != 'Alipay'">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("bank_info_0012") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('bank_info_0013')" v-model="bankInfoForm.BankAddress" />
                </div>
              </div>
            </div>
            <!-- <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label ">{{ $t('member_info_0007') }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('member_info_0008')" v-model="bankInfoForm.Mobile">
                </div>
              </div>
            </div> -->
            <div class="form-item">
              <a class="smart-select smart-select-init" data-open-in="sheet">
                <select v-model="bankInfoForm.BankName">
                  <option v-for="item in bankList" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("bank_info_0014") }}</div>
                    <!-- <div class="item-after item-input-wrap">{{ bankList[0] ? bankList[0][1] : '' }}</div> -->
                    <div class="item-after item-input-wrap max"></div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </f7-block>
        <div class="list inset button-save">
          <ul>
            <li>
              <a href="#" class="list-button button-single" @click="bankInfoSubmit">{{ $t("member_info_0017") }}</a>
            </li>
          </ul>
          <p>{{ $t("common_0004") }}：</p>
          <p style="font-size: 15px">{{ $t("bank_info_0015") }}</p>
          <!-- <p style="font-size:15px;">{{ $t('bank_info_0016') }}</p>
          <p style="font-size:15px;">{{ $t('bank_info_0017') }}</p> -->
        </div>
      </template>
      <template v-if="memberBankCardList.length > 0">
        <f7-block v-for="(item, index) in memberBankCardList" :key="index" class="block-bank-info">
          <div class="card bank-card">
            <div class="card-header">
              <div class="card-avatar"><img src="../../../static/images/yt999/banklogo/bankcode.png" width="32" height="32" /></div>
              <div class="card-name">{{ item.BankName ? item.BankName : item.BankAddress }}</div>
              <div style="width: 10%"><img class="eyepng" @click="oneye(index, item)" :src="`../../../static/images/yt999/banklogo/${item.eye ? 'eyeon' : 'eyeoff'}.png`" width="30" height="30" /></div>
            </div>
            <div class="card-content">
              <div class="card-info text-26">
                <span v-if="item.eye">{{ item.BankAccountNo }}</span>
                <span v-else>{{ item.BankAccountNo | formatPhoneNumber }}</span>
              </div>
              <div v-show="item.HolderName" class="card-info card-address text-16">
                <span>※</span>
                <span>{{ item.HolderName }}</span>
              </div>
              <div v-show="item.BankAddress" class="card-info card-address text-16">
                <span>※</span>
                <span>{{ item.BankAddress }}</span>
              </div>
            </div>
          </div>
        </f7-block>
      </template>
    </template>
  </f7-page>
</template>
<script>
import { getUserInfo, getBlankList, bankbind, checkSecurePwdStatus } from "../../../axios/api";
import changeSecurityPwd from "../../template/change-security-pwd";
let self;
export default {
  components: {
    changeSecurityPwd,
  },
  props: {},
  data() {
    return {
      userInfo: {},
      bindBankInfoStatus: false,
      bankList: [],
      bankList_Virtul: [],
      bankInfoForm: {
        IsVirtualCurrencyBank: false,
        BankAccountNo_Virtul: "",
        BankAccountNo: "",
        SureName: "",
        BankAddress: "",
        BankName: "",
        BankName_Virtul: "",
        Mobile: "",
      },
      memberBankCardList: [],
      SureNameFlag: false,
      showInfo: -1,
      isBinding: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getUserInfoEvent() {
      getUserInfo().then((data) => {
        if (data.Code === "NoError") {
          self.memberBankCardList = data.Data.MemberBankCardList;
          self.memberBankCardList.forEach((element) => {
            element.eye = false;
          });
          if (data.Data.BankAccountNo) {
            self.bindBankInfoStatus = true;
          }
          if (data.Data.SureName === "") {
            self.SureNameFlag = true;
          }
          self.userInfo = data.Data;
        }
      });
    },
    oneye(id, item) {
      item.eye = !item.eye;
      this.memberBankCardList.splice(id, item);
    },
    onCopy(e) {
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    getBankListEvent() {
      const self = this;
      getBlankList().then((data) => {
        if (data.Code === "NoError") {
          self.bankList = data.Data.filter((item) => item[2] === false);
          // if (self.bankList.length > 0) {
          //   self.bankInfoForm.BankName = self.bankList[0][0];
          // }
          self.bankList_Virtul = data.Data.filter((item) => item[2] === true);
          // if (self.bankList_Virtul.length > 0) {
          //   self.bankInfoForm.BankName_Virtul = self.bankList_Virtul[0][0];
          // }
        }
      });
    },
    choosePoint(isVirtul) {
      this.bankInfoForm.IsVirtualCurrencyBank = isVirtul;
    },
    bankInfoSubmit() {
      const self = this;
      if (this.bankInfoForm.IsVirtualCurrencyBank) {
        if (!self.bankInfoForm.BankAccountNo_Virtul) {
          self.$f7.dialog.alert(this.$t("bank_info_0026"), this.$t("common_0004"));
          return false;
        }
        if (!self.bankInfoForm.BankName_Virtul) {
          self.$f7.dialog.alert(this.$t("bank_info_0028"), this.$t("common_0004"));
          return false;
        }
      } else {
        let sureName = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/; //真实姓名验证表达式
        if (self.userInfo.SureName === "") {
          //无姓名则需要输入验证
          if (!self.bankInfoForm.SureName) {
            self.$f7.dialog.alert(this.$t("bank_info_0018"), this.$t("common_0004"));
            return false;
          }
        }
        // if (!sureName.test(self.bankInfoForm.SureName) && self.bankInfoForm.SureName)
        // {
        //       self.$f7.dialog.alert(this.$t('bank_info_0019'), this.$t('common_0004'));
        //       return false;
        // }

        if (!self.bankInfoForm.BankAccountNo) {
          self.$f7.dialog.alert(this.$t("bank_info_0020"), this.$t("common_0004"));
          return false;
        }
        //验证银行卡
        const Reg = /^\d{10,16}$/;
        if (!Reg.test(self.bankInfoForm.BankAccountNo)) {
          self.$f7.dialog.alert(this.$t("bank_info_0031"), this.$t("common_0004"));
          return false;
        }
        if (!self.bankInfoForm.BankAddress) {
          self.$f7.dialog.alert(this.$t("bank_info_0021"), this.$t("common_0004"));
          return false;
        }
        self.bankInfoForm.Mobile = "--";
        // if (!self.bankInfoForm.Mobile) {
        //   self.$f7.dialog.alert(this.$t('member_info_0008'), this.$t('common_0004'));
        //   return false;
        // }
        // let phoneNumberCheck = /^\+?[0-9]{1,4}(?:\s?[0-9]{1,4})*$/;
        // if (!phoneNumberCheck.test(self.bankInfoForm.Mobile)){
        //   self.$f7.dialog.alert(this.$t('member_info_0019'), this.$t('common_0004'));
        //   return false;
        // }
        if (!self.bankInfoForm.BankName) {
          self.$f7.dialog.alert(this.$t("bank_info_0022"), this.$t("common_0004"));
          return false;
        }
      }

      const param = { data: JSON.stringify(self.bankInfoForm) };
      bankbind(param).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(this.$t("bank_info_0023"), this.$t("common_0004"), () => {
            self.$f7router.navigate("/member-center/");
          });
          self.getUserInfoEvent();
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    back() {
      this.$f7router.navigate("/member-center/");
      // if (this.$f7route.params.Id == 1) {
      //   this.$f7router.back();
      // } else {
      //   this.$f7router.navigate("/member-center/");
      // }
    },
    handleCustomEvent(value) {
      console.log(value);
      this.showInfo = 2;
      this.isBinding = true;
    },
  },
  created() {
    self = this;
    this.getBankListEvent();
    this.getUserInfoEvent();
    checkSecurePwdStatus().then((data) => {
      if (data.Code === "NoError") {
        self.isBinding = data.Data.IsBinding;
        if (!data.Data.IsBinding) {
          self.$f7.dialog.alert(this.$t("bank_info_0024"), this.$t("common_0004"), function () {
            // self.$f7router.navigate("/member-center/change-security-pwd/");
          });
          this.showInfo = 1;
        } else {
          this.showInfo = 2;
        }
      } else {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
      }
    });
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-bank-info {
  --f7-page-toolbar-bottom-offset: 0;
  .row-choose {
    margin-bottom: 10px;
    .col-50 {
      min-height: 67px;
      text-align: center;
      color: #000;
      font-size: 16px;
      border: 1px solid #cecece;
      border-radius: 5px;
      border-radius: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 10px 0;
        color: #464646;
      }
      .itme-point {
        color: #000;
      }
    }
    .active {
      background-color: #e5e4dc;
      color: #000;
      background-size: 100% 100%;
      border: 1px solid #cecece;
      p {
        color: #fff;
      }
      .itme-point {
        color: #000;
      }
    }
  }
  .bank-card {
    margin: 0px;
    background: -webkit-linear-gradient(#00153f, #4c6497);
    background: -o-linear-gradient(#00153f, #4c6497);
    background: -moz-linear-gradient(#00153f, #4c6497);
    background: -mos-linear-gradient(#00153f, #4c6497);
    background: linear-gradient(#00153f, #4c6497);
    border-radius: 8px;
    color: #fff;

    .card-header {
      justify-content: flex-start;
      margin: 0 12px;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #53668f;

      .card-avatar {
        width: 10%;
        img {
          vertical-align: middle;
        }
      }

      .card-name,
      .card-accountname {
        width: 80%;
        margin: 0 10px;
        font-size: 16px;
        position: relative;
      }

      // .card-name:after {
      //   content: "";
      //   position: absolute;
      //   width: 1px;
      //   height: 15px;
      //   //background-color: #fff;
      //   right: -10px;
      //   top: 4px;
      //   display: block;
      // }
    }

    .card-header:after {
      height: 0;
    }

    .card-content {
      .card-info {
        margin: 6px 10px;
        padding: 5px 0;
        .eyepng {
          position: relative;
          top: 6px;
          width: 30px;
          height: 30px;
        }
      }

      .card-address {
        margin-top: -10px;
      }
    }

    .card-footer {
      padding: 15px 8px;

      .block {
        width: 100%;

        .button {
          background-color: #3569cf;
          color: #ffffff;
          font-size: 10px;
        }
      }
    }

    .card-footer:before {
      height: 0;
    }
  }
  .block-bank-title {
    color: var(--f7-THEME-color);
    margin: 20px 0 10px;
  }
  .block-bank-info {
    margin: 10px 12px 12px;
    padding: 0;
    .max {
      max-width: 100%;
    }
    .form {
      .item-title {
        color: #464646;
      }
      .item-input-wrap {
        // width: 100%;
        background: #fafafa;
        border: 1px solid #1b2a3b10;
        padding: 10px;
        border-radius: 6px;
        margin: 2px 0 10px;
        min-height: 20px;
        input {
          width: 100%;
        }
      }
    }
  }
  .button-save {
    margin: 20px 12px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: var(--f7-button-text-color);
        }
      }
    }
  }
}
</style>

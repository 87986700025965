// import Vue from 'vue';
// import Router from 'vue-router';
// Vue.use(Router);

import { getCookie, setCookie } from "../config/utils";
import HomePage from "../pages/home.vue";
import GameCategoryList from "../pages/game-category-list.vue";
import FishList from "../pages/fish-list.vue";

import NotFoundPage from "../pages/404.vue";
import TestPage from "../pages/test.vue";
import LoginPage from "../pages/login/login.vue";
import MemberCenter from "../pages/users/member-center.vue";
import Register from "../pages/register/register.vue";
import LimitedAccess from "../pages/limited-access.vue";
import DailyTask from "../pages/promotion/daily-task.vue";
import Activity from "../pages/promotion/activity.vue";
import ApplyActivity from "../pages/promotion/apply-activity.vue";

import Deposit from "../pages/users/account-center/deposit.vue";
import DepositQA from "../pages/users/account-center/deposit-qa.vue";
import DepositCS from "../pages/users/account-center/online-cs.vue";
import DepositDetails from "../pages/users/account-center/deposit-details.vue";
import InitOnlinePayment from "../pages/users/account-center/init-online-payment.vue";
import Transfer from "../pages/users/account-center/transfer.vue";
import Safe from "../pages/users/account-center/safe.vue";
import SafeBox from "../pages/users/account-center/safe-box.vue";
import SafeRecords from "../pages/users/account-center/safe-records.vue";
import Withdraw from "../pages/users/account-center/withdraw.vue";
import VipClub from "../pages/users/account-center/vip-club.vue";
import GameList from "../pages/egame/game-list.vue";

import ActivitiesSearch from "../pages/users/self-service/activities-search.vue";
import ActivitiesReview from "../pages/users/self-service/activities-review.vue";
import BuffetPromotion from "../pages/users/self-service/buffet-promotion.vue";
import DailyTaskSearch from "../pages/users/self-service/daily-task-search.vue";
import DailyTaskReview from "../pages/users/self-service/daily-task-review.vue";
import PointCenter from "../pages/users/self-service/point-center.vue";
import SignIn from "../pages/users/self-service/sign-in.vue";
import VipBirthdayGift from "../pages/users/self-service/vip-birthday-gift.vue";
import VipHolidayGift from "../pages/users/self-service/vip-holiday-gift.vue";
import VipMonthlySalary from "../pages/users/self-service/vip-monthly-salary.vue";

import DepositSearch from "../pages/users/account-list/deposit-search.vue";
import DepositRecords from "../pages/users/account-list/deposit-records.vue";
import WithdrawSearch from "../pages/users/account-list/withdraw-search.vue";
import WithdrawRecords from "../pages/users/account-list/withdraw-records.vue";
import FundsSearch from "../pages/users/account-list/funds-search.vue";
import FundsRecords from "../pages/users/account-list/funds-records.vue";
import IntegralSearch from "../pages/users/account-list/integral-search.vue";
import IntegralRecords from "../pages/users/account-list/integral-records.vue";
import ActivitySearch from "../pages/users/account-list/activity-search.vue";
import ActivityRecords from "../pages/users/account-list/activity-records.vue";
import BettingSearch from "../pages/users/account-list/betting-search.vue";
import BettingRecords from "../pages/users/account-list/betting-records.vue";

import MemberInfo from "../pages/users/personal-information/member-info.vue";
import BankInfo from "../pages/users/personal-information/bank-info.vue";
import ChangeLoginPwd from "../pages/users/personal-information/change-login-pwd.vue";
import ChangeSecurityPwd from "../pages/users/personal-information/change-security-pwd.vue";
import SetEncrypted from "../pages/users/personal-information/set-encrypted.vue";

import MessageCenter from "../pages/users/message/message-center.vue";
import FinancialCenter from "../pages/users/financial/financial-center.vue";
import FinancialDetail from "../pages/users/financial/financial-detail.vue";
import RegisterAgent from "../pages/register/register-agent.vue";

import LoadingGame from "../pages/loading-game.vue";

import Referrer from "../pages/users/account-list/referrer.vue";
import Share from "../pages/users/account-list/referrer-share.vue";
import ReferrerReceiveSearch from "../pages/users/account-list/referrer-receive-search.vue";
import ReferrerReceiveRecords from "../pages/users/account-list/referrer-receive-records.vue";
import ReferrerDirectReportSearch from "../pages/users/account-list/referrer-directReport-search.vue";
import ReferrerDirectReportRecords from "../pages/users/account-list/referrer-directReport-records.vue";
import ReferrerPerformanceSearch from "../pages/users/account-list/referrer-performance-search.vue";
import ReferrerPerformanceRecords from "../pages/users/account-list/referrer-performance-records.vue";

import ActivityCenter from "../pages/activity/activity-center.vue";

import OrderCenter from "../pages/users/account-list/order-center.vue";
import OrderSubmission from "../pages/users/account-list/order-submission.vue";

import InviteFriends from "../pages/users/friends/invite-friends.vue";
import InviteOverview from "../pages/users/friends/invite-overview.vue";
import FriendDetails from "../pages/users/friends/friends-details.vue";
import CommissionDetails from "../pages/users/friends/commission-details.vue";
import Vip from "../pages/users/financial/vip.vue";
import Award from "../pages/promotion/award.vue";
import Luckybox from "../pages/promotion/luckybox.vue";
import Cis from "../pages/promotion/cis.vue";
import About from "../pages/about.vue";
import Myposition from "../pages/users/position/myPosition.vue";
import JobDetail from "../pages/users/position/jobDetail.vue";
import JobApplication from "../pages/users/position/jobApplication.vue";
var routes = [
  {
    name: "home",
    path: "/",
    // component: HomePage,
    async(routeTo, routeFrom, resolve, reject) {
      if(!getCookie('zz')) {
        resolve({ component: LoginPage });
      } else {
        resolve({component: HomePage});
      }
  },
  },
  {
    name: "home2",
    path: "/home/",
    async(routeTo, routeFrom, resolve, reject) {
      if(!getCookie('zz')) {
        resolve({ component: LoginPage });
      } else {
        resolve({component: HomePage});
      }
    },
  },
  {
    name: "test",
    path: "/test/",
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: TestPage });
      }
    },
  },
  {
    name: "award",
    path: "/award/",
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: Award });
      }
    },
  },
  {
    name: "luckybox",
    path: "/luckybox/",
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: Luckybox });
      }
    },
  },
  {
    name: "about",
    path: "/about/",
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: About });
      }
    },
  },
  {
    name: "game-category-list",
    path: "/game-category-list/:GameType/",
    component: GameCategoryList,
  },
  {
    name: "fish-list",
    path: "/fish-list/",
    component: FishList,
  },
  {
    name: "app",
    path: "/app/",
    redirect: function (route, resolve, reject) {
      const app = this.app;
      window.localStorage.setItem("r", route.query.r ? route.query.r : "");
      window.localStorage.setItem("cid", route.query.cid ? route.query.cid : "");
      if (route.query.zz) {
        if ((route.query.zz = "")) {
          setCookie("zz", "", -1);
        } else {
          setCookie("zz", route.query.zz, 7);
        }
      }
      if (route.query.device) {
        window.localStorage.setItem("device", route.query.device);
        window.localStorage.setItem("d", route.query.d);
        window.localStorage.setItem("appVer", route.query.appVer ? route.query.appVer : "");
        app.isApp = route.query.device;
        //resolve('/');
      }
      //else reject();
      resolve("/");
    },
  },
  {
    name: "login",
    path: "/login/",
    component: LoginPage,
  },
  {
    name: "member-center",
    path: "/member-center/",
    //component: MemberCenter,
    routes: [
      {
        name: "deposit",
        path: "/deposit/",
        //component: Deposit,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Deposit });
          }
        },
      },
      {
        name: "deposit-qa",
        path: "/deposit-qa/:Id/",
        //component: Deposit,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DepositQA });
          }
        },
      },
      {
        name: "online-cs",
        path: "/online-cs/",
        //component: Deposit,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DepositCS });
          }
        },
      },
      {
        name: "deposit-details",
        path: "/deposit-details/",
        //component: Deposit,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DepositDetails });
          }
        },
      },
      {
        name: "init-online-payment",
        path: "/init-online-payment/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: InitOnlinePayment });
          }
        },
      },
      {
        name: "transfer",
        path: "/transfer/",
        //component: Transfer,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Transfer });
          }
        },
      },
      {
        name: "safe",
        path: "/safe/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Safe });
          }
        },
      },
      {
        name: "safeBox",
        path: "/safeBox/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: SafeBox });
          }
        },
      },
      {
        name: "safe-records",
        path: "/safe-records/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: SafeRecords });
          }
        },
      },
      {
        name: "referrer",
        path: "/referrer/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Referrer });
          }
        },
      },
      {
        name: "referrer-share",
        path: "/referrer-share/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Share });
          }
        },
      },
      {
        name: "referrer-receive-search",
        path: "/referrer-receive-search/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerReceiveSearch });
          }
        },
      },
      {
        name: "referrer-receive-records",
        path: "/referrer-receive-records/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerReceiveRecords });
          }
        },
      },
      {
        name: "referrer-directReport-search",
        path: "/referrer-directReport-search/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerDirectReportSearch });
          }
        },
      },
      {
        name: "referrer-directReport-records",
        path: "/referrer-directReport-records/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerDirectReportRecords });
          }
        },
      },
      {
        name: "referrer-performance-search",
        path: "/referrer-performance-search/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerPerformanceSearch });
          }
        },
      },
      {
        name: "referrer-performance-records",
        path: "/referrer-performance-records/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ReferrerPerformanceRecords });
          }
        },
      },
      {
        name: "withdraw",
        path: "/withdraw/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Withdraw });
          }
        },
      },
      {
        name: "vip-club",
        path: "/vip-club/",
        //component: VipClub,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: VipClub });
          }
        },
      },
      {
        name: "sign-in",
        path: "/sign-in/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: SignIn });
          }
        },
      },
      {
        name: "vip-monthly-salary",
        path: "/vip-monthly-salary/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: VipMonthlySalary });
          }
        },
      },
      {
        name: "vip-birthday-gift",
        path: "/vip-birthday-gift/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: VipBirthdayGift });
          }
        },
      },
      {
        name: "vip-holiday-gift",
        path: "/vip-holiday-gift/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: VipHolidayGift });
          }
        },
      },
      {
        name: "buffet-promotion",
        path: "/buffet-promotion/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: BuffetPromotion });
          }
        },
      },
      {
        name: "point-center",
        path: "/point-center/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: PointCenter });
          }
        },
      },
      {
        name: "daily-task-search",
        path: "/daily-task-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DailyTaskSearch });
          }
        },
      },
      {
        name: "daily-task-review",
        path: "/daily-task-review/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DailyTaskReview });
          }
        },
      },
      {
        name: "activities-search",
        path: "/activities-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ActivitiesSearch });
          }
        },
      },
      {
        name: "activities-review",
        path: "/activities-review/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ActivitiesReview });
          }
        },
      },
      {
        name: "deposit-search",
        path: "/deposit-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DepositSearch });
          }
        },
      },
      {
        name: "deposit-records",
        path: "/deposit-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: DepositRecords });
          }
        },
      },
      {
        name: "withdraw-search",
        path: "/withdraw-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: WithdrawSearch });
          }
        },
      },
      {
        name: "withdraw-records",
        path: "/withdraw-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: WithdrawRecords });
          }
        },
      },
      {
        name: "funds-search",
        path: "/funds-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: FundsSearch });
          }
        },
      },
      {
        name: "funds-records",
        path: "/funds-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: FundsRecords });
          }
        },
      },
      {
        name: "integral-search",
        path: "/integral-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: IntegralSearch });
          }
        },
      },
      {
        name: "integral-records",
        path: "/integral-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: IntegralRecords });
          }
        },
      },
      {
        name: "activity-search",
        path: "/activity-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ActivitySearch });
          }
        },
      },
      {
        name: "activity-records",
        path: "/activity-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ActivityRecords });
          }
        },
      },
      {
        name: "betting-search",
        path: "/order-search/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: BettingSearch });
          }
        },
      },
      {
        name: "betting-records",
        path: "/order-records/:Info/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: BettingRecords });
          }
        },
      },
      {
        name: "member-info",
        path: "/member-info",
        //component: Withdraw,
        tabs: [          
          {
            path: 'tab-personal',
            id: 'tab-personal',
          },
          {
            path: '/tab-identity/',
            id: 'tab-identity',
          },
        ],
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: MemberInfo });
          }
        },        
      },
      {
        name: "bank-info",
        path: "/bank-info/:Id/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: BankInfo });
          }
        },
      },
      {
        name: "change-login-pwd",
        path: "/change-login-pwd/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ChangeLoginPwd });
          }
        },
      },
      {
        name: "change-security-pwd",
        path: "/change-security-pwd/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: ChangeSecurityPwd });
          }
        },
      },
      {
        name: "set-encrypted",
        path: "/set-encrypted/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: SetEncrypted });
          }
        },
      },
      {
        name: "message-center",
        path: "/message-center/:Id/",
        //component: Withdraw,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: MessageCenter });
          }
        },
      },
      {
        name: "financial-center",
        path: "/financial-center/:Id/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: FinancialCenter });
          }
        },
      },
      {
        name: "financial-detail",
        path: "/financial-detail/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: FinancialDetail });
          }
        },
      },
      {
        name: "invite-friends",
        path: "/invite-friends/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: InviteFriends });
          }
        },
      },
      {
        name: "invite-overview",
        path: "/invite-overview/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: InviteOverview });
          }
        },
      },
      {
        name: "friend-details",
        path: "/friend-details/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: FriendDetails });
          }
        },
      },
      {
        name: "myPosition",
        path: "/myPosition/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Myposition });
          }
        },
      },
      {
        name: "jobDetail",
        path: "/jobDetail/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: JobDetail });
          }
        },
      },
      {
        name: "jobApplication",
        path: "/jobApplication/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: JobApplication });
          }
        },
      },
      {
        name: "commission-details",
        path: "/commission-details/:Info/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: CommissionDetails });
          }
        },
      },
      {
        name: "vip",
        path: "/vip/:vipLevel/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: Vip });
          }
        },
      },
      
      {
        name: "order-center",
        path: "/order-center/:Id/",
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: OrderCenter });
          }
        },
      },
      {
        name: "order-submission",
        path: "/order-submission/:Id/",
        component: OrderSubmission,
        async(routeTo, routeFrom, resolve, reject) {
          if (!getCookie("zz")) {
            resolve({ component: LoginPage });
          } else {
            resolve({ component: OrderSubmission });
          }
        },
      },
    ],
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: MemberCenter });
      }
    },
  },
  {
    name: "activity-center",
    path: "/activity-center/:missionId/",
    //component: ActivityCenter,
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: ActivityCenter });
      }
    },
  },
  {
    name: "register",
    path: "/register/",
    component: Register,
  },
  {
    name: "limited-access",
    path: "/limited-access/",
    component: LimitedAccess,
  },
  {
    name: "daily-task",
    path: "/daily-task/",
    //component: DailyTask,
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: DailyTask });
      }
    },
  },
  {
    name: "activity",
    path: "/activity/:Id/",
    //component: Activity,
    routes: [
      {
        name: "apply",
        path: "/apply/:Id/",
        component: ApplyActivity,
      },
    ],
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: Activity });
      }
    },
  },
  {
    name: "game-list",
    path: "/game-list/:Id/",
    //component: GameList,
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: GameList });
      }
    },
  },
  {
    name: "cis",
    path: "/cis/",
    //component: GameList,
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: Cis });
      }
    },
  },
  {
    name: "register-agent",
    path: "/register-agent/",
    component: RegisterAgent,
  },
  {
    name: "loading-game",
    path: "/loading-game/",
    async(routeTo, routeFrom, resolve, reject) {
      if (!getCookie("zz")) {
        resolve({ component: LoginPage });
      } else {
        resolve({ component: LoadingGame });
      }
    },
  },
  {
    path: "(.*)",
    redirect: "/",
  },
];
// routes.forEach((to, from, next) => {
//   const isAuthenticated=false;
//   if (!isAuthenticated && to.path !== '/login/' && to.path!=='/register' && to.path!=='/register-agent') {
//     // If the user is not authenticated and is trying to access a page other than login, redirect to login
//     next('/login/');
//   } else {
//       // Otherwise, proceed as normal
//       next();
//   }
// });
export default routes;
